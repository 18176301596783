<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('benefits') }}</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">{{ $t('dashboard') }}</li>
                     <li class="breadcrumb-item active">{{ $t('benefits') }}</li>
                     <li v-if="isEditing" class="breadcrumb-item active">{{ $t('edit_benefit') }}</li>
                     <li v-else class="breadcrumb-item active">{{ $t('create_benefit') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-12">
                  <div class="card card-outline card-info">

                     <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else>
                        <!-- card-header -->
                        <div class="card-header">
                           <h3 class="card-title">
                              {{ isEditing === false ? $t('create_benefit') : $t('edit_benefit') }}
                           </h3>
                        </div>

                        <form @submit.prevent="save">
                           <div class="card-body">
                              <div class="row">
                                 <div class="col-6">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('name') }}</label>
                                       <input type="text" v-model="benefit.name" class="form-control">
                                    </div>
                                 </div>

                                 <div class="col-2 mt-3">
                                    <div class="form-group">
                                       <div class="custom-control custom-switch pt-2 custom-switch-off-danger custom-switch-on-success">
                                          <input type="checkbox" v-model="benefit.isHighlighted" class="custom-control-input" id="customSwitchHighlighted">
                                          <label class="custom-control-label" for="customSwitchHighlighted">
                                             {{ benefit.isHighlighted == true ? $t('highlighted') : $t('standard') }}
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <!-- ./row -->
                           </div>
                           <div class="card-footer">
                              <div class="row justify-content-end">
                                 <SaveButton
                                    :buttonName="saveButtonName"
                                    :isSaving="isSaving"
                                 >
                                 </SaveButton>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
               <!-- /.col-->
            </div>
            <!-- ./row -->
         </div>
      </section>
   </div>
</template>

<script>
import SaveButton from "../../../components/SaveButton.vue";
import { v4 as uuidV4 } from "uuid";
import { useToast } from "vue-toastification";
import benefitService from "../services/benefit-service";

export default {
   name: "BenefitsForm",
   setup() {
      const toast = useToast()
      return { toast }
   },
   components: {
      SaveButton
   },
   data () {
      return {
         benefit: {
            uuid: uuidV4(),
            name: null,
            isHighlighted: false
         },
         isLoading: false,
         isEditing: false,
         isSaving: false,
         errors: []
      }
   },
   computed: {
      saveButtonName () {
         if (this.isEditing)
            return 'edit';
         else
            return 'save';
      }
   },
   async created() {
      this.isLoading = true;

      try {
         if (this.$route.params.uuid){
            this.isEditing = true;

            const { data: response } = await benefitService.get(this.$route.params.uuid);
            this.benefit = response;
         }
      } finally {
         this.isLoading = false;
      }
   },
   methods: {
      async save() {
         this.isSaving = true;
         try {
            if (this.isEditing) {
               await benefitService.save('edit', this.benefit);
               this.$router.push({ name: 'benefits' });
               this.toast.success(this.$t("updated"));
            }
            else {
               await benefitService.save('create', this.benefit);
               this.$router.push({ name: 'benefits' });
               this.toast.success(this.$t("saved"));
            }
         } catch (error) {
            const { status } = error.response;
            const errorResponse = error.response.data.errors;

            if (this.isEditing)
               this.toast.error(this.$t("update_failure"));
            else
               this.toast.error(this.$t("save_failure"));

            if (status === 422){
               this.errors = Object.assign(this.errors, errorResponse)
               Object.keys(this.errors).forEach((field) => {
                  this.errors[field].forEach((error) => {
                     this.toast.error(error);
                  });
               });
            }
         } finally {
            this.isSaving = false;
         }
      }
   }
}
</script>


